import { mmcAjax } from '@/common/js/mmcModule';

export function postReport(data) {
    return mmcAjax({
        method: 'post',
        url: `${window.APIBasePath}mmc/project/report`,
        data,
        crossDomain: true,
    })
}
