<template>
    <div class="payment_box">
        <h3 class="title">{{list[showItem]}}</h3>
        <div class="textarea hairline bottom">
            <h4>昵称（必填）</h4>
            <input type="text" v-model="name" @blur="validForm(1)" placeholder="您的昵称">
            <p v-if="nameMsg">{{nameMsg}}</p>
        </div>
        <div class="textarea hairline bottom">
            <h4>联系方式（必填）</h4>
            <input type="tel" v-model="tel" @blur="validForm(2)" placeholder="填写联系方式，方便我们核实项目，与您联系">
            <p v-if="telMsg">{{telMsg}}</p>
        </div>
        <div class="textarea hairline bottom">
            <h4>举报内容（必填）</h4>
<!--            <input v-model="inter" type="text"  >-->
            <textarea class="text_area" v-model="inter" name="" placeholder="输入具体内容（不少于30字）" id="" @blur="validForm(3)" cols="30" rows="10"></textarea>
            <p v-if="interMsg">{{interMsg}}</p>
        </div>
        <div class="textarea">
            <h4>上传图片（选填）</h4>
            <mmcUploaderDefault style="margin-top: 20px" @changeend="afterRead"/>
        </div>
        <button class="submit_btn" @click="submitForm">提交</button>
    </div>
</template>
<script>
import mmcUploaderDefault from '@/components/mmcUI/mmc-uploader-default';
import { postReport } from './api/api';
import utils from "@/common/js/utils";
const params = utils.getRequestParams();
import { shareProj } from '@/common/js/mmcModule';
import { authChecker } from '@/common/js/mmcModule';
export default {
    data() {
        return {
            name: '',
            tel: '',
            inter: '',
            showItem: 0,
            list: [
                '存在欺诈骗钱行为',
                '存在违法行为',
                '存在侵权行为',
                '其他问题'
            ],
            fileList: [],
            nameMsg: '',
            telMsg: '',
            interMsg: '',
            mmc_client_uuid: ''
        }
    },
    components: {
        mmcUploaderDefault
    },
    mounted() {
        this.showItem = this.$route.query.item;
        const that = this
        authChecker.check({ notneedphone: 1 },(res)=>{
            console.log(res);
            if(res.code === 0) {
                that.mmc_client_uuid = res.data.mmc_client_uuid
            }
            that.initShare();
        });
    },
    methods: {
        initShare() {
            const { projuuid } = params;
            const shareParams = {
                projuuid
            };
            shareProj.init(shareParams);
        },
        afterRead(file) {
           this.fileList = file;
        },
        submitForm() {
            if(!this.name || this.nameMsg) {
                alertMsg('请填写昵称');
                return
            }else if(!this.tel || this.telMsg) {
                alertMsg('请填写联系电话')
                return;
            }else if(!this.inter|| this.interMsg) {
                alertMsg('请填写举报内容')
                return;
            }
            if((this.nameMsg || this.telMsg || this.interMsg)) {
                return
            }
            const that = this;
            postReport({
                user_id: this.mmc_client_uuid,
                uuid: this.$route.query.projuuid,
                reporter_name: this.name,
                report_type: Number(this.$route.query.item) +1,
                images: this.fileList,
                phone: this.tel,
                report_detail: this.inter
            }).then(res => {
                if(res.code === 0) {
                    alertMsg('举报成功，感谢您的反馈');
                    setTimeout(()=>{
                        location.href = `${window.BasePath}fund/project/detail?projuuid=${that.$route.query.projuuid}`
                    }, 2000);
                }
            })
        },
        validForm(e) {
            if(e === 1 && (this.name.length < 2 || this.name.length > 10)){
                this.nameMsg = '请输入2-10个字符'
            }else{
                this.nameMsg = ''
            }
            if(e === 2 && !(/^1[3456789]\d{9}$/.test(this.tel))){
                this.telMsg = '请输入正确的手机号码'
            }else{
                this.telMsg = ''
            }
            if(e === 3 && this.inter.length < 30){
                this.interMsg = '请输入至少30个字的举报内容'
            }else{
                this.interMsg = ''
            }
        }
    }
}
</script>
<style scoped lang="less">
    .payment_box {
        padding: 35px 20px;
        .title {
            font-size: 34px;
            text-align: left;
            margin-bottom: 35px;
        }
        .text_area {
            border: none;
            width: 100%;
            max-height: 40px;
            padding: 15px 0;
            min-height: 58px;
            font-size: 14px;
            margin-top: 10px;
            &::-webkit-input-placeholder{
                color: #ddd;
            }
        }
        .textarea {
            position: relative;
            p {
                position: absolute;
                color: red;
                bottom: -25px;
                right: 5px;
            }
            h4 {
                font-size: 14px;
                text-align: left;
            }
            margin-top: 25px;
            align-items: center;
            padding-bottom: 14px;
            input{
                margin-top: 12px;
                width: 100%;
                border: none;
                font-size: 14px;
            }
            input::-webkit-input-placeholder{
                color: #ddd;
            }
            .up_icon {
                margin-top: 20px;
                width: 73px;
                height: 73px;
                background: url(./image/upfile.png);
                background-size: 100% 100%;
            }

        }
        .submit_btn {
            margin-top: 40px;
            width: 100%;
            height: 50px;
            background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
            box-shadow: 0 4px 30px 0 rgba(0,155,255,0.40);
            border-radius: 4px;
            font-size: 18px;
            border: none;
            color: #FFFFFF;
            text-align: center;
            line-height: 50px;
        }
    }
</style>
